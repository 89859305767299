<template>
  <div class="panel login-panel" v-show="visible">
    <div class="panel-head">{{ title }}</div>
    <div class="panel-body">
      <el-form ref="loginForm" :rules="rules" :model="model" method="post" action="'/auth/login" id="loginForm"
               onsubmit="return false" label-width="80px">
        <el-form-item prop="loginAccount" label="登录账号">
          <el-input v-model="model.loginAccount" placeholder="登录账号"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="loginPassword">
          <el-input v-model="model.loginPassword" type="password" placeholder="登录密码"></el-input>
        </el-form-item>


        <el-form-item label="验证码" prop="_captcha">
          <el-input :maxlength="4" placeholder="验证码" v-model="model._captcha" style="width: 100px"></el-input>
          <captcha-image ref="captcha-image"/>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" :loading="locked" @click="submitForm('loginForm')">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import CaptchaImage from '../common/CaptchaImage'

export default {
  name: 'UserAuthLogin',
  components: {CaptchaImage},
  data: function () {
    return {
      visible: false,
      title: '账号登录',
      model: {
        loginAccount: '',
        loginPassword: '',
        _captcha: ''
      },
      locked: false,
      rules: {
        loginAccount: [
          {required: true, message: '请输入登录账号', 'trigger': 'blur'},
          {max: 36, message: '登录账号长度超过限制', 'trigger': 'blur'}
        ],
        loginPassword: [
          {required: true, message: '请输入登录密码', 'trigger': 'blur'},
          {min: 6, max: 16, message: '密码长度范围为6-16个字符', 'trigger': 'blur'}
        ],
        _captcha: [
          {required: true, message: '请输入验证码', 'trigger': 'blur'}
        ]
      }
    }
  },
  mounted() {
    const self = this
    setTimeout(function () {
      self.visible = true
    }, 500)
    window.location.href = 'https://adm.dkir.cn'
  },
  methods: {
    submitForm(formName) {
      const self = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          self.locked = true
          self.$http.post({
            url: '/admin/auth/login.action',
            data: self.model,
            success(res) {
              localStorage.accessToken = res.data
              self.$router.push({name: 'home'})
            },
            fail(response) {
              self.$refs['captcha-image'].changeImg()
              self.$http.errorHandle(response)
            },
            always() {
              self.locked = false
            }
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>
<style scoped>
.login-panel {
  height: 320px;
  margin-top: -160px;
}

form {
  padding-right: 15px;
}
</style>
