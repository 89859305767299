import BaseEnum from '@/common/BaseEnum'

class PlatformEnum extends BaseEnum {

  constructor() {
    super()

    this.MP = super.put(1, 'MP')
    this.ADQ = super.put(2, 'ADQ')
    this.VIVO = super.put(3, 'VIVO')
    this.OPPO = super.put(4, 'OPPO')
    this.UC = super.put(5, 'UC')
    this.QIANCHUAN = super.put(7, '千川')
  }

}

const o = new PlatformEnum()
export default o