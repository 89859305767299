let ElSortPlugin = {}
ElSortPlugin.install = (Vue) => {


  Vue.mixin({

    methods: {
      elSort ({ prop, order}) {
        this.query.orderColumn = prop
        if (order === 'ascending') {
          this.query.orderType = 'ASC'
        } else if (order === 'descending') {
          this.query.orderType = 'DESC'
        } else {
          this.query.orderType = ''
          this.query.orderColumn = ''
        }
        this.query.pageNum = 1
        return this.pagingSearch()
      }
    }


  })

}
export default ElSortPlugin