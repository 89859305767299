<template>
  <div class="wrap">
    <i class="el-icon-s-unfold open" v-show="!navVisible" @click="navVisible=true"></i>
    <i class="el-icon-s-fold close" v-show="navVisible" @click="navVisible=false"></i>
    <div :class="navClass">
      <my-nav></my-nav>
    </div>

    <div :class="contentClass">
      <router-view style="padding: 15px"></router-view>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
  import MyNav from './MyNav'

  export default {
    name: 'ColumnTwo.vue',
    components: {MyNav},
    computed: {
      navClass () {
        return this.navVisible ? 'nav' : 'nav nav-hide'
      },
      contentClass () {
        return this.navVisible ? 'content' : 'content content-hide'
      }
    },
    data () {
      return {
        navVisible: true
      }
    }
  }
</script>

<style scoped>
  .nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 200px;
  }

  .content {
    float: right;
    height: 100%;
    width: calc(100% - 200px);
  }

  .nav-hide {
    width: 0;
    display: none;
  }

  .content-hide {
    width: 100%;
  }


  .open, .close {
    font-size: 30px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99999;
  }


</style>