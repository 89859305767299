<template>
  <div style="width: 400px">
    <el-form method="post" :model="model" ref="buyVipForm" id="buyVipForm"
             onsubmit="return false" label-width="80px">


      <el-form-item label="客户编号">
        {{ model.id }}
      </el-form-item>


      <el-form-item label="客户名称" prop="customerName">
        <el-input v-model="model.customerName"></el-input>
      </el-form-item>

      <el-form-item label="agencyId" prop="agencyId">
        <el-input v-model="model.agencyId"></el-input>
      </el-form-item>

      <el-form-item label="账号数量" prop="userMax">
        <el-input-number v-model="model.userMax" :min="1" :max="1000"></el-input-number>
      </el-form-item>

      <el-form-item label="购买价格" prop="price">
        <el-input v-model="model.price">
          <span slot="suffix">元</span>
        </el-input>

      </el-form-item>

      <el-form-item label="购买备注" prop="remark">
        <el-input v-model="model.remark"></el-input>
      </el-form-item>

      <el-form-item label="购买时长" prop="availableDays">
        <el-input-number v-model="model.availableDays" :min="1" :max="365"></el-input-number>
        (当前：{{ availableDays }})
      </el-form-item>


      <el-form-item>
        <el-button type="primary" :loading="loading" @click="submitForm">提交</el-button>
      </el-form-item>

      <div class="clear"></div>
    </el-form>
  </div>
</template>

<script>
import dateUtil from 'tdw-date-util'
import objectUtil from 'tdw-object-util'

export default {
  name: 'BuyVip',
  data () {
    return {
      loading: false,
      title: '购买套餐',
      model: {
        customerName: '',
        remark: '',
        userMax: '1',
        agencyId: '',
        availableDays: 365,
        id: this.$route.params.id
      },
      availableDays: 0,
      dateUtil
    }
  },
  beforeCreate () {
    const self = this
    self.$http.get({
      url: '/admin/customer/view.do',
      data: {id: this.$route.params.id},
      success (res) {
        self.model.remark = ''
        self.model.userMax = res.data.userMax
        self.model.agencyId = res.data.agencyId
        self.model.availableDays = res.data.availableDays
        self.model.customerName = res.data.remark
        self.availableDays = res.data.availableDays
        self.model.availableDays = 365
      }
    })
  },
  methods: {
    submitForm () {
      const self = this
      self.loading = true
      const data = objectUtil.copy(self.model)
      data.price *= 100
      self.$http.post({
        url: '/admin/customer/buyVip.do',
        data,
        success () {
          self.$message.success('操作成功')
          self.$router.back()
        },
        always () {
          self.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>