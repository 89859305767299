import BaseEnum from './BaseEnum'

class VipLevelEnum extends BaseEnum {
  constructor() {
    super()
    this.FREE_LEVEL = super.put(1, '体验版')
    this.BASE_LEVEL = super.put(10, '基础版')
    this.ADVANCE_LEVEL = super.put(15, '高级版')
    this.ULTIMATE_VIP = super.put(20, '旗舰版')
  }



}

const o = new VipLevelEnum()
export default o

