<template>
  <div>
    <div>
      <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">

        <el-form-item label="客户编号">
          <el-input v-model="query.teamId" :clearable="true"></el-input>
        </el-form-item>

        <el-form-item label="登录账号">
          <el-input v-model="query.loginAccount" :clearable="true"></el-input>
        </el-form-item>


        <el-form-item label="角色">
          <el-select v-model="query.userRole" placeholder="请选择">
            <el-option
                key="-1"
                label="全部"
                value="">
            </el-option>
            <el-option
                v-for="(name,value) in userRoleMap"
                :key="value"
                :label="name"
                :value="value">
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="vip">
          <el-select v-model="query.vipFlag" placeholder="请选择">
            <el-option
                key="-1"
                label="全部"
                value="">
            </el-option>
            <el-option
                v-for="(name,value) in VipFlagMap"
                :key="value"
                :label="name"
                :value="value">
            </el-option>
          </el-select>

        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="pagingSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="clear"></div>
    </div>


    <el-table
        :data="data.list"
        border
        class="mt10"
        @sort-change="elSort"
        :default-sort="{prop: 'id', order: 'descending'}"
        style="width: 100%">

      <el-table-column
          prop="id"
          align="center"
          width="100"
          label="ID"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      />


      <el-table-column
          prop="teamId"
          align="center"
          width="150"
          label="客户编号"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'customerList', query:{id:scope.row.teamId}}">{{ scope.row.teamId }}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
          prop="vipFlag"
          align="center"
          label="类型"
      >
        <template slot-scope="scope">
          {{ VipFlagMap[scope.row.vipFlag.toString()] }}
        </template>
      </el-table-column>


      <el-table-column
          prop="loginAccount"
          align="center"
          label="登录账号"
      >
      </el-table-column>


      <el-table-column prop="username" label="姓名" align="center"></el-table-column>


      <el-table-column prop="vipLevel" label="套餐" align="center">
        <template slot-scope="scope">
          {{ VipLevelEnum.getLabel(scope.row.vipLevel) }}
        </template>
      </el-table-column>

      <el-table-column prop="platformSupported" label="平台" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.vipLevel === VipLevelEnum.ULTIMATE_VIP.value">
            全平台
          </span>
          <span v-else>
             {{ PlatformEnum.getLabel(scope.row.platformSupported) }}
              <template v-if="scope.row.platformSupported2>0">
                ,{{ PlatformEnum.getLabel(scope.row.platformSupported2) }}
              </template>
          </span>
        </template>
      </el-table-column>


      <el-table-column
          prop="userRole"
          align="center"
          label="角色"
      >
        <template slot-scope="scope">
          {{ userRoleMap[scope.row.userRole] }}
        </template>
      </el-table-column>


      <el-table-column
          prop="clientBind"
          header-align="center"
          align="right"
          label="客户端已绑定"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
      </el-table-column>


      <el-table-column
          prop="expireTime"
          align="center"
          label="到期时间"
          width="160"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
        <template slot-scope="scope">
          <span
              :class="scope.row.expireTime>timestamp?'success':'warn'">{{
              dateUtil.format(scope.row.expireTime)
            }}</span>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          label="注册时间"
          width="160"
      >
        <template slot-scope="scope">
          {{ dateUtil.format(scope.row.createdAt) }}
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          label="操作"
          width="400"
      >

        <template slot-scope="scope">
          <el-button size="mini" @click="$router.push({name:'extendExpireTime', params:{id:scope.row.id}})">到期设置
          </el-button>
          <el-button size="mini" @click="$router.push({name:'updateUser', params:{id:scope.row.id}})" class="ml10">
            修改信息
          </el-button>

          <el-button size="mini" @click="$router.push({name:'clientList', query:{userId:scope.row.id}})" class="ml10">
            查看设备
          </el-button>

          <el-button size="mini" @click="$router.push({name:'refund', params:{id:scope.row.id}})" class="ml10">
            处理退款
          </el-button>
        </template>

      </el-table-column>


    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>
  </div>
</template>

<script>
import urlUtil from 'tdw-url-util'
import dateUtil from 'tdw-date-util'
import MyPagination from '../common/MyPagination'
import VipFlagMap from '../../common/VipFlagMap'
import VipLevelEnum from '../../common/VipLevelEnum'
import PlatformEnum from '../../common/PlatformEnum'

export default {
  name: 'UserList',
  components: {MyPagination},
  data() {
    return {
      title: '账号列表',
      groupMap: {},
      proxyMap: {},
      usePagingPlugin: true,
      query: {
        pageNum: urlUtil.getPara('pageNum', 1),
        pageSize: 20,
        orderColumn: 'teamId',
        orderType: 'desc',
        teamId: urlUtil.getPara('teamId'),
        userId: urlUtil.getPara('userId'),
        loginAccount: urlUtil.getPara('loginAccount'),
        mobile: urlUtil.getPara('mobile'),
        userRole: urlUtil.getPara('userRole'),
        vipFlag: urlUtil.getPara('vipFlag')
      },
      queryUrl: '/admin/user/list.do',
      data: {list: []},
      dateUtil,
      VipFlagMap,
      timestamp: new Date().getTime(),
      userRoleMap: {
        '1': '经理',
        '2': '员工'
      },
      PlatformEnum,
      VipLevelEnum
    }
  },
}
</script>

<style scoped>

</style>