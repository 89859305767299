<template>
  <div>
    <div class="pull-left">
      <slot name="left-extend"></slot>
    </div>
    <el-pagination class="mt10 pull-right" layout="total, prev, pager, next" :total="Number(pager.total)"
                   :page-size="pager.pageSize" :page-count="pager.pageTotal" :current-page="pager.pageNum"

                   @current-change="goPage">
    </el-pagination>
    <div class="clear"></div>
  </div>
</template>
<script>
  export default {
    name: 'MyPagination',
    props: {
      pager: {
        type: Object,
        'default': {
          pageNum: 1,
          pageSize: 20,
          itemTotal: 0,
          pageTotal: 0
        }
      }
    },
    methods: {
      goPage: function (pageNum) {
        this.$emit('goPage', pageNum)
      }
    }
  }
</script>


