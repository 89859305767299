import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import VueRouter from 'vue-router'
import routes from './routes'
import http from './common/http'
import VuePagingQueryPlugin from 'tdw-vue-paging-query-plugin'
import VuePageTitlePlugin from 'tdw-vue-page-title'
import './assets/global.css'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/iconfont/iconfont.css'
import ElSortPlugin from './common/ElSortPlugin'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueRouter)
http.$message = ElementUI.Message
Vue.prototype.$http = http
Vue.use(VuePagingQueryPlugin, {http, changeUrl: true})
Vue.use(VuePageTitlePlugin)
Vue.use(ElSortPlugin)
new Vue({
  router: new VueRouter(routes),
  render: h => h(App)
}).$mount('#app')

