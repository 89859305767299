<template>
  <div>
    <el-page-header @back="$router.back()" content="修改信息" class="mb20">
    </el-page-header>

    <el-form method="post" :model="model" ref="buyVipForm" id="buyVipForm"
             onsubmit="return false" label-width="80px">


      <el-form-item label="客户编号">
        {{ model.teamId }}
      </el-form-item>

      <el-form-item label="登录账号">
        {{ model.loginAccount }}
      </el-form-item>

      <el-form-item label="用户名称">
        {{ model.username }}
      </el-form-item>


      <el-form-item label="注册时间">
        {{ dateUtil.format(model.createdAt) }}
      </el-form-item>

      <el-form-item label="到期时间">
        {{ dateUtil.format(model.expireTime) }}
      </el-form-item>


      <el-form-item label="开通套餐" prop="vipLevel">
        <el-col :span="6">
          <el-radio v-model="model.vipLevel" :label="VipLevelEnum.BASE_LEVEL.value">
            {{ VipLevelEnum.BASE_LEVEL.label }}
          </el-radio>
          <el-radio v-model="model.vipLevel" :label="VipLevelEnum.ADVANCE_LEVEL.value">
            {{ VipLevelEnum.ADVANCE_LEVEL.label }}
          </el-radio>
          <el-radio v-model="model.vipLevel" :label="VipLevelEnum.ULTIMATE_VIP.value">
            {{ VipLevelEnum.ULTIMATE_VIP.label }}
          </el-radio>

        </el-col>
      </el-form-item>

      <el-form-item label="开通平台" prop="platformSupported" v-if="model.vipLevel!==VipLevelEnum.ULTIMATE_VIP.value">


        <el-checkbox-group v-model="platformSupported">

          <template v-for="( label, value) in PlatformEnum.map">
            <el-checkbox :label="value" :key="value">{{ label }}</el-checkbox>

          </template>

        </el-checkbox-group>


      </el-form-item>

      <el-form-item>
        <el-button type="primary" :loading="loading" @click="submitForm">提交</el-button>
      </el-form-item>

      <div class="clear"></div>
    </el-form>
  </div>
</template>

<script>
import dateUtil from 'tdw-date-util'
import VipLevelEnum from '../../common/VipLevelEnum'
import PlatformEnum from '../../common/PlatformEnum'

export default {
  name: 'UpdateUserForm',
  data() {
    return {
      loading: false,
      title: '修改信息',
      model: {
        id: this.$route.params.id,
        vipLevel: 0,
        platformSupported: 0,
        platformSupported2: 0
      },
      user: {},
      dateUtil,
      VipLevelEnum,
      PlatformEnum,
      platformSupported: []
    }
  },
  beforeCreate() {
    const self = this
    self.$http.get({
      url: '/admin/user/view.do',
      data: {userId: this.$route.params.id},
      success(res) {
        self.model = res.data

        if (res.data.platformSupported) {
          self.platformSupported.push(res.data.platformSupported.toString())
        }
        if (res.data.platformSupported2) {
          self.platformSupported.push(res.data.platformSupported2.toString())
        }
      }
    })
  },
  methods: {
    submitForm() {
      const self = this


      if (self.model.vipLevel === VipLevelEnum.ADVANCE_LEVEL.value) {
        if (self.platformSupported.length !== 2) {
          return self.$message.error('请选择2个平台')
        }
        self.model.platformSupported = self.platformSupported[0]
        self.model.platformSupported2 = self.platformSupported[1]
      }

      if (self.model.vipLevel === VipLevelEnum.BASE_LEVEL.value) {
        if (self.platformSupported.length !== 1) {
          return self.$message.error('请选择1个平台')
        }
        self.model.platformSupported = self.platformSupported[0]
      }


      self.loading = true


      self.$http.post({
        url: '/admin/user/update.do',
        data: {
          id: this.$route.params.id,
          vipLevel: this.model.vipLevel,
          platformSupported: this.model.platformSupported,
          platformSupported2: this.model.platformSupported2
        },
        success() {
          self.$message.success('操作成功')
          self.$router.back()
        },
        always() {
          self.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>