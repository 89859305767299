<template>
  <div>
    <el-input v-if="editable" :autofocus="true" size="mini" @change="change" @blur="editable=false"
              v-model="value"></el-input>
    <span v-else>{{ value ? value : name }}<i class="el-icon-edit-outline" @click="showInput"></i></span>
  </div>
</template>

<script>
export default {
  name: "RemarkInput",
  props: {
    id: [String, Number],
    name: String
  },
  data () {
    return {
      editable: false,
      value: ''
    }
  },
  methods: {
    showInput ($event) {
      const e = $event.target.parentNode.parentNode
      setTimeout(function () {
        e.querySelector('input').focus()
      }, 100)
      this.editable = true
      if (this.value === '') {
        this.value = this.name
      }
    },
    change () {
      console.log(this.value)
      const self = this
      self.$http.post({
        url: '/admin/customer/updateRemark.do',
        data: {
          customerId: this.id,
          remark: this.value
        },
        success () {
          self.editable = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>