<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      loading: null
    }
  },
  created() {
    if (this.$route.query) {
      localStorage.softVersion = this.$route.query.version
    }
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    if (!localStorage.accessToken) {
      this.$router.push({name: 'authLogin'})
      this.loading.close()
      return
    }
    const self = this
    this.$http.post({
      url: '/admin/auth/isLogin.do',
      success() {
        self.$router.push({name: 'customerList'})
      },
      fail() {
        console.info('请先登录')
        self.$router.push({name: 'authLogin'})
      },
      always() {
        self.loading.close()
      }
    })
  }
}
</script>

