<template>
  <div>
    <el-form method="post" :rules="rules" :model="model" ref="passwordForm"  id="passwordForm"
             onsubmit="return false" label-width="80px">


      <el-form-item label="当前密码" prop="passwordOld">
        <el-input v-model="model.passwordOld" type="password"></el-input>
      </el-form-item>

      <el-form-item label="新的密码" prop="passwordNew">
        <el-input v-model="model.passwordNew" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="passwordConfirm">
        <el-input v-model="model.passwordConfirm" type="password"></el-input>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" :loading="loading" @click="submitForm('passwordForm')">提交</el-button>
      </el-form-item>

      <div class="clear"></div>
    </el-form>
  </div>
</template>

<script>
  import http from '../../common/http'

  export default {
    name: 'UpdatePassword',
    data () {
      const self = this
      return {
        title: '修改密码',
        loading: false,
        model: {
          passwordOld: '',
          passwordNew: '',
          passwordConfirm: ''
        },
        rules: {
          passwordOld: [
            {required: true, message: '请输入当前登录密码', 'trigger': 'blur'}
          ],
          passwordNew: [
            {required: true, message: '请输入新登录密码', 'trigger': 'blur'},
            {min: 6, max: 16, message: '密码长度范围为6-16个字符', 'trigger': 'blur'}
          ],
          passwordConfirm: [
            {required: true, message: '请再次输入新登录密码', 'trigger': 'blur'},
            {
              validator (rule, value, callback) {
                if (value !== self.model.passwordNew) {
                  callback(new Error('两次密码输入不一致'))
                } else {
                  callback()
                }
              },
              'trigger': 'blur'
            }
          ]
        }
      }
    },
    methods: {
      submitForm (formName) {
        const self = this
        this.$refs[formName].validate((valid) => {
          if (!valid) {
            return false
          }
          self.loading = true
          http.post({
            url: '/admin/info/updatePassword.do',
            data: self.model,
            success () {
              self.$message.success('操作成功')
              self.model = {}
            },
            fail (res) {
              self.$message.error(self.$http.getError(res))
            },
            always () {
              self.loading = false
            }
          })
        })
      }
    }
  }
</script>

<style scoped>
  #passwordForm {
    width: 400px;
  }
</style>