import HelloWorld from './components/HelloWorld'
import PopPanel from './components/layouts/PopPanel'
import Login from './components/auth/Login'
import ColumnTwo from './components/layouts/ColumnTwo'
import UpdatePassword from './components/admin/UpdatePassword'


import CustomerList from './components/customer/CustomerList'
import BuyVip from './components/customer/BuyVip'
import UserList from './components/user/UserList'
import ExtendExpireTime from './components/user/ExtendExpireTime'
import ClientList from './components/client/ClientList'
import TaskList from './components/task/TaskList'

import VipRenewList from '@/components/vipRenew/VipRenewList'
import TokenVerify from './components/auth/TokenVerify'
import UpdateUserForm from './components/user/UpdateUserForm'
import RefundCreate from "@/components/user/RefundCreate";
export default {
  linkActiveClass: 'active',
  routes: [
    {
      path: '/auth',
      component: PopPanel,
      children: [
        {name: 'authLogin', path: 'login', component: Login},
        {name: 'authVerify', path: 'verify/:token', component: TokenVerify},
      ]
    },
    {
      path: '/',
      component: ColumnTwo,
      children: [
        {name: 'home', path: '/', component: HelloWorld},
        {name: 'updatePassword', path: '/updatePassword', component: UpdatePassword},
        {name: 'updateUser', path: '/updateUser/:id', component: UpdateUserForm},
        {name: 'customerList', path: '/customerList', component: CustomerList},
        {name: 'buyVip', path: '/buyVip/:id', component: BuyVip},
        {name: 'userList', path: '/userList', component: UserList},
        {name: 'extendExpireTime', path: '/extendExpireTime/:id', component: ExtendExpireTime},
        {name: 'refund', path: '/refund/:id', component: RefundCreate},
        {name: 'clientList', path: '/clientList', component: ClientList},
        {name: 'taskList', path: '/taskList', component: TaskList},
        {name: 'vipRenew', path: '/vipRenew', component: VipRenewList}
      ]
    }
  ]
}