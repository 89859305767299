<template>
  <div>
    登录成功
  </div>
</template>

<script>
export default {
  name: "TokenVerify",
  mounted() {
    localStorage.accessToken = this.$route.params.token
    if (this.$route.query.from) {
      window.location.href = this.$route.query.from
    } else {
      this.$router.push({name: 'home'})
    }
  }
}
</script>

<style scoped>

</style>