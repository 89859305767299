<template>
  <div>
    <div>
      <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">

        <el-form-item label="客户编号">
          <el-input v-model="query.customerId" :clearable="true"></el-input>
        </el-form-item>

        <el-form-item label="userId">
          <el-input v-model="query.userId" :clearable="true"></el-input>
        </el-form-item>

        <el-form-item label="version">
          <el-input v-model="query.clientVersion" :clearable="true"></el-input>
        </el-form-item>

        <el-form-item label="agencyId">
          <el-input v-model="query.agencyId" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="agencyName">
          <el-input v-model="query.agencyName" :clearable="true"></el-input>
        </el-form-item>


        <el-form-item label="类型">
          <el-select v-model="query.taskType" placeholder="请选择">
            <el-option
                    key="-1"
                    label="全部"
                    value="">
            </el-option>
            <el-option
                    v-for="(name,value) in TaskTypeMap"
                    :key="value"
                    :label="name"
                    :value="value">
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="平台">
          <el-select v-model="query.platform" placeholder="请选择">
            <el-option
                    key="-1"
                    label="全部"
                    value="">
            </el-option>
            <el-option
                    v-for="(name,value) in PlatformMap"
                    :key="value"
                    :label="name"
                    :value="value">
            </el-option>
          </el-select>

        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="pagingSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="clear"></div>
    </div>


    <el-table
            :data="data.list"
            border
            class="mt10"
            @sort-change="elSort"
            :default-sort="{prop: 'taskId', order: 'descending'}"
            style="width: 100%">

      <el-table-column
              prop="taskId"
              align="center"
              width="100"
              label="ID"
              sortable="custom" :sort-orders="[ 'descending','ascending']"
      />


      <el-table-column
              prop="customerId"
              align="center"
              width="100"
              label="客户编号"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'customerList', query:{customerId:scope.row.customerId}}">{{scope.row.customerId}}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
              prop="clientVersion"
              align="center"
              width="100"
              label="version"
      />
      <el-table-column
              prop="userId"
              align="center"
              width="100"
              label="userId"
      />

      <el-table-column
              prop="taskType"
              align="center"
              label="任务类型"
              width="100"
      >
        <template slot-scope="scope">
          {{TaskTypeMap[scope.row.taskType.toString()]}}
        </template>
      </el-table-column>

      <el-table-column
              prop="platform"
              align="center"
              label="平台"
              width="80"
      >
        <template slot-scope="scope">
          {{PlatformMap[scope.row.platform.toString()]}}
        </template>
      </el-table-column>



      <el-table-column
              prop="rawPageFlag"
              align="center"
              label="原生页"
              width="100"
      >
        <template slot-scope="scope">
          {{YesNoMap[scope.row.rawPageFlag.toString()]}}
        </template>
      </el-table-column>
      <el-table-column
              prop="targetFlag"
              align="center"
              label="定向"
              width="100"
      >
        <template slot-scope="scope">
          {{YesNoMap[scope.row.targetFlag.toString()]}}
        </template>
      </el-table-column>

      <el-table-column
              prop="agencyName"
              align="left"
              header-align="center"
              label="agencyName"
      >
      </el-table-column>
      <el-table-column
              prop="agencyId"
              align="left"
              header-align="center"
              label="agencyId"
      >
      </el-table-column>



      <el-table-column
              align="center"
              label="绑定时间"
              width="160"
      >
        <template slot-scope="scope">
          {{dateUtil.format(scope.row.createdAt)}}
        </template>
      </el-table-column>


    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>
  </div>
</template>

<script>
  import urlUtil from 'tdw-url-util'
  import dateUtil from 'tdw-date-util'
  import MyPagination from '../common/MyPagination'
  import YesNoMap from '../../assets/YesNoMap'
  import PlatformMap from '../../common/PlatformMap'
  import TaskTypeMap from '../../common/TaskTypeMap'

  export default {
    name: 'ClientList',
    components: {MyPagination},
    data () {
      return {
        title: '任务列表',
        groupMap: {},
        proxyMap: {},
        usePagingPlugin: true,
        query: {
          pageNum: urlUtil.getPara('pageNum', 1),
          pageSize: 20,
          orderColumn: 'id',
          orderType: 'desc',
          customerId: urlUtil.getPara('customerId'),
          userId: urlUtil.getPara('userId'),
          taskType: urlUtil.getPara('taskType'),
          platform: urlUtil.getPara('platform'),
          agencyId: urlUtil.getPara('agencyId'),
          agencyName: urlUtil.getPara('agencyName'),
          rawPageFlag: urlUtil.getPara('rawPageFlag'),
          targetFlag: urlUtil.getPara('targetFlag'),
          clientVersion: urlUtil.getPara('clientVersion')
        },
        queryUrl: '/admin/task/list.do',
        data: {list: []},
        dateUtil,
        TaskTypeMap,
        PlatformMap,
        YesNoMap
      }
    }
  }
</script>

<style scoped>

</style>