<template>
  <div>
    <div>
      <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">

        <el-form-item label="客户编号">
          <el-input v-model="query.customerId" :clearable="true"></el-input>
        </el-form-item>

        <el-form-item label="userId">
          <el-input v-model="query.userId" :clearable="true"></el-input>
        </el-form-item>


        <el-form-item label="解绑">
          <el-select v-model="query.deleted" placeholder="请选择">
            <el-option
                    key="-1"
                    label="全部"
                    value="">
            </el-option>
            <el-option
                    v-for="(name,value) in YesNoMap"
                    :key="value"
                    :label="name"
                    :value="value">
            </el-option>
          </el-select>

        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="pagingSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="clear"></div>
    </div>


    <el-table
            :data="data.list"
            border
            class="mt10"
            @sort-change="elSort"
            :default-sort="{prop: 'id', order: 'descending'}"
            style="width: 100%">

      <el-table-column
              prop="id"
              align="center"
              width="100"
              label="ID"
              sortable="custom" :sort-orders="[ 'descending','ascending']"
      />


      <el-table-column
              prop="customerId"
              align="center"
              width="100"
              label="客户编号"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'customerList', query:{customerId:scope.row.customerId}}">{{scope.row.customerId}}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
              prop="userId"
              align="center"
              width="100"
              label="userId"
      />

      <el-table-column
              prop="deleted"
              align="center"
              label="解绑"
              width="80"
      >
        <template slot-scope="scope">
          {{YesNoMap[scope.row.deleted.toString()]}}
        </template>
      </el-table-column>

      <el-table-column
              prop="userAgent"
              align="left"
              header-align="center"
              label="userAgent"
      >
      </el-table-column>


      <el-table-column
              prop="userScreen"
              align="center"
              label="userScreen"
              width="100"
      >
      </el-table-column>


      <el-table-column
              align="center"
              label="绑定时间"
              width="160"
      >
        <template slot-scope="scope">
          {{dateUtil.format(scope.row.createdAt)}}
        </template>
      </el-table-column>

      <el-table-column
              align="center"
              label="操作"
              width="100"
      >

        <template slot-scope="scope">
          <span v-if="scope.row.deleted">-</span>
          <el-popconfirm
                  title="确认解绑？"
                  @confirm="unbind(scope.row.id)"
                  v-else
          >
            <el-button type="primary" plain slot="reference">解绑</el-button>
          </el-popconfirm>
        </template>

      </el-table-column>


    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>
  </div>
</template>

<script>
  import urlUtil from 'tdw-url-util'
  import dateUtil from 'tdw-date-util'
  import MyPagination from '../common/MyPagination'
  import YesNoMap from '../../assets/YesNoMap'

  export default {
    name: 'ClientList',
    components: {MyPagination},
    data () {
      return {
        title: '绑定列表',
        groupMap: {},
        proxyMap: {},
        usePagingPlugin: true,
        query: {
          pageNum: urlUtil.getPara('pageNum', 1),
          pageSize: 20,
          orderColumn: 'id',
          orderType: 'desc',
          customerId: urlUtil.getPara('customerId'),
          userId: urlUtil.getPara('userId'),
          deleted: urlUtil.getPara('deleted')
        },
        queryUrl: '/admin/client/list.do',
        data: {list: []},
        dateUtil,
        YesNoMap
      }
    },
    methods: {
      unbind (id) {
        console.info('unbind', id)
        const self = this
        this.$http.post({
          url: '/admin/client/unbind.do',
          data: {id},
          success () {
            self.dialogVisible = false
            self.$message.success('解绑成功')
            self.pagingSearch()
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>