class BaseEnum {
  constructor () {
    this._map = {}
  }

  put (value, label) {
    this._map[value] = label
    return {value: value, label: label}
  }

  get map () {
    return this._map
  }

  getLabel (value, defaultValue) {
    return this._map[value] !== undefined ? this._map[value] : defaultValue
  }
}

export default BaseEnum