<template>
  <div>
    <div>
      <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">

        <el-form-item label="客户编号">
          <el-input v-model="query.id" :clearable="true"></el-input>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="query.remark" :clearable="true"></el-input>
        </el-form-item>

        <el-form-item label="vip">
          <el-select v-model="query.vipFlag" placeholder="请选择">
            <el-option
                key="-1"
                label="全部"
                value="">
            </el-option>
            <el-option
                v-for="(name,value) in VipFlagMap"
                :key="value"
                :label="name"
                :value="value">
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="pagingSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="clear"></div>
    </div>


    <el-table
        :data="data.list"
        border
        class="mt10"
        @sort-change="elSort"
        :default-sort="{prop: 'id', order: 'descending'}"
        style="width: 100%">

      <el-table-column
          prop="id"
          align="center"
          label="客户编号"
          width="120"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'userList', query:{teamId:scope.row.id}}">{{ scope.row.id }}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
          prop="vipFlag"
          align="center"
          label="类型"
          width="120"
      >
        <template slot-scope="scope">
          {{ VipFlagMap[scope.row.vipFlag.toString()] }}
        </template>
      </el-table-column>




      <el-table-column
          prop="userNum"
          header-align="center"
          align="right"
          label="成员数量"
          width="120"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
        <template slot-scope="scope">
          {{scope.row.accountNum}}
        </template>
      </el-table-column>

      <el-table-column
          prop="availableDays"
          header-align="center"
          align="right"
          label="时长"
          width="120"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
      </el-table-column>


      <el-table-column
          prop="agencyId"
          align="center"
          label="代理商"
          width="120"
      >
      </el-table-column>

      <el-table-column
          prop="remark"
          align="left"
          label="备注"
          header-align="center"

      >
        <template slot-scope="scope">
          <remark-input :name="scope.row.remark" :id="scope.row.id"/>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          label="创建时间"
          width="160"
      >
        <template slot-scope="scope">
          {{ dateUtil.format(scope.row.createdAt) }}
        </template>
      </el-table-column>




    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>
  </div>
</template>

<script>
import urlUtil from 'tdw-url-util'
import dateUtil from 'tdw-date-util'
import MyPagination from '../common/MyPagination'
import VipFlagMap from '../../common/VipFlagMap'
import RemarkInput from '@/components/customer/RemarkInput'

export default {
  name: 'CustomerList',
  components: {RemarkInput, MyPagination},
  data () {
    return {
      title: '客户列表',
      groupMap: {},
      proxyMap: {},
      usePagingPlugin: true,
      query: {
        pageNum: urlUtil.getPara('pageNum', 1),
        pageSize: 20,
        orderColumn: 'id',
        orderType: 'desc',
        vipFlag: urlUtil.getPara('vipFlag'),
        id: urlUtil.getPara('id'),
        remark: urlUtil.getPara('remark')
      },
      VipFlagMap,
      queryUrl: '/admin/customer/list.do',
      data: {list: []},
      dateUtil
    }
  },
}
</script>

<style scoped>

</style>