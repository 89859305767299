<template>
  <div>

    <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">

      <el-form-item label="客户编号">
        <el-input v-model="query.customerId" :clearable="true"></el-input>
      </el-form-item>

      <el-form-item label="userId">
        <el-input v-model="query.userId" :clearable="true"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="pagingSearch">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
        :data="data.list"
        border
        class="mt10"
        @sort-change="elSort"
        :default-sort="{prop: 'id', order: 'descending'}"
        style="width: 100%">

      <el-table-column
          prop="id"
          align="center"
          width="160"
          label="时间"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
        <template slot-scope="scope">
          {{ dateUtil.format(scope.row.createdAt) }}
        </template>

      </el-table-column>


      <el-table-column
          prop="customerId"
          align="center"
          width="160"
          label="客户编号"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'customerList', query:{customerId:scope.row.customerId}}">{{ scope.row.customerId }}
          </router-link>
        </template>
      </el-table-column>


      <el-table-column
          prop="userId"
          align="center"
          label="userId"
          width="160"
      />

      <el-table-column
          prop="price"
          header-align="center"
          align="right"
          label="价格"
          width="160"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
        <template slot-scope="scope">{{scope.row.price/100}}</template>
      </el-table-column>
      <el-table-column
          prop="days"
          header-align="center"
          align="right"
          label="时长"
          width="160"
          sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
      </el-table-column>


      <el-table-column
          prop="expireTime"
          align="center"
          label="续期前"
          width="160"
      >
        <template slot-scope="scope">
          {{dateUtil.format(scope.row.oldVipExpired)}}
        </template>
      </el-table-column>
      <el-table-column
          prop="expireTime"
          align="center"
          label="续期后"
          width="160"
      >
        <template slot-scope="scope">
          {{dateUtil.format(scope.row.newVipExpired)}}
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          label="备注"
          prop="remark"
      >

      </el-table-column>



    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>

  </div>
</template>

<script>
import urlUtil from 'tdw-url-util'
import dateUtil from 'tdw-date-util'
import MyPagination from '@/components/common/MyPagination'

export default {
  name: "VipRenewList",
  components: {MyPagination},
  data () {
    return {
      title: '续期记录',
      groupMap: {},
      proxyMap: {},
      usePagingPlugin: true,
      query: {
        pageNum: urlUtil.getPara('pageNum', 1),
        pageSize: 20,
        orderColumn: 'id',
        orderType: 'desc',
        customerId: urlUtil.getPara('customerId'),
        userId: urlUtil.getPara('userId')
      },
      queryUrl: '/admin/vipRenew/list.do',
      data: {list: []},
      dateUtil
    }
  }
}
</script>

<style scoped>

</style>