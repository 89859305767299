<template>
  <el-menu
      :default-active="activeIndex"
  >


    <el-menu-item index="1" @click="goto('customerList')">
      <i class="el-icon-s-custom"></i>
      <span slot="title">客户列表</span>
    </el-menu-item>

    <el-menu-item index="2" @click="goto('userList')">
      <i class="el-icon-user"></i>
      <span slot="title">账号列表</span>
    </el-menu-item>

    <el-menu-item index="3" @click="goto('clientList')">
      <i class="el-icon-eleme"></i>
      <span slot="title">绑定列表</span>
    </el-menu-item>


    <el-menu-item index="4" @click="goto('taskList')">
      <i class="el-icon-tickets"></i>
      <span slot="title">任务列表</span>
    </el-menu-item>

    <el-menu-item index="5" @click="goto('vipRenew')">
      <i class="el-icon-tickets"></i>
      <span slot="title">续费记录</span>
    </el-menu-item>


    <el-menu-item index="6" @click="goto('updatePassword')">
      <i class="el-icon-ext iconfont iconmima"></i>
      <span slot="title">密码设置</span>
    </el-menu-item>

  </el-menu>
</template>

<script>
export default {
  name: 'MyNav',
  computed: {
    activeIndex () {
      const map = {
        customerList: '1',
        userList: '2',
        clientList: '3',
        taskList: '4',
        vipRenew: '5',
        updatePassword: '6'
      }
      return map[this.$route.name] || '1'
    }
  },
  methods: {
    goto (name) {
      if (this.$route.name !== name) {
        this.$router.push({name})
      }
    }
  }
}
</script>

<style scoped>

</style>