<template>
  <div style="display: inline-block">
    <img :src="imgUrl" class="captcha ml10" v-show="imgUrl"/>
    <a href="javascript:" @click="changeImg" class="ml10">换一张</a>
  </div>
</template>

<script>
  export default {
    name: 'CaptchaImage',
    data () {
      return {
        imgUrl: ''
      }
    },
    mounted () {
      this.changeImg()
    },
    methods: {
      changeImg: function () {
        this.imgUrl = this.$http.baseUrl() + '/common/captcha.jpg' + '?' + new Date().getTime()
      }
    }
  }
</script>

<style scoped>
  .captcha {
    vertical-align: middle;
  }
</style>