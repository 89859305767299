<template>
  <div>
    <el-page-header @back="$router.back()" content="退款处理" class="mb20">
    </el-page-header>


    <el-form method="post" :model="model" ref="refundForm" id="refundForm"
             onsubmit="return false" label-width="80px">


      <el-form-item label="客户编号">
        {{ user.teamId }}
      </el-form-item>

      <el-form-item label="登录账号">
        {{ user.loginAccount }}
      </el-form-item>


      <el-form-item label="用户名称">
        {{ user.username }}
      </el-form-item>


      <el-form-item label="注册时间">
        {{ dateUtil.format(user.createdAt) }}
      </el-form-item>

      <el-form-item label="到期时间">
        {{ dateUtil.format(user.expireTime) }}
      </el-form-item>


      <el-form-item label="退款金额" prop="refundMoney">
        <el-input v-model="model.refundMoney" style="width: 180px">
          <span slot="suffix">元</span>
        </el-input>

      </el-form-item>



      <el-form-item>
        <el-button type="primary" :loading="loading" @click="submitForm">提交</el-button>
      </el-form-item>

      <div class="clear"></div>
    </el-form>
  </div>
</template>

<script>
import dateUtil from 'tdw-date-util'
import objectUtil from 'tdw-object-util'

export default {
  name: 'RefundCreate',
  data() {
    return {
      loading: false,
      title: '处理退款',
      model: {
        userId: this.$route.params.id,
        refundMoney: 0
      },
      user: {},
      dateUtil
    }
  },
  beforeCreate() {
    const self = this
    self.$http.get({
      url: '/admin/user/view.do',
      data: {userId: this.$route.params.id},
      success(res) {
        self.user = res.data
      }
    })
  },
  methods: {
    submitForm() {
      const self = this


      self.loading = true


      const data = objectUtil.copy(self.model)
      data.refundMoney =  parseInt(data.refundMoney*100, 0)
      self.$http.post({
        url: '/admin/user/refund.do',
        data,
        success() {
          self.$message.success('操作成功')
          self.$router.back()
        },
        always() {
          self.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>